<template>
  <div>
    <h3 class="mt-5">
      {{
        $getVisibleNames(
          "manual.administrar_seccion",
          true,
          "Administrar Secciones"
        )
      }}
      <AlternativeNameButton
        :table_name="'manual.administrar_seccion'"
        :allows_crud="allows_crud"
      ></AlternativeNameButton>
      <!-- Administrar Secciones -->
    </h3>
    <div class="mt-5 section-container">
      <!-- :level="level"
        :period_id="period_id"
        :career_id="career_id"
        :campus_id="campus_id"
        :modality_id="modality_id" -->
      <SectionList :allows_crud="allows_crud"></SectionList>
      <!-- @update_section_list="slotUpdateSectionList"
        @changed_egress_profile="slotChangeEgressProfile" -->
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "SectionView",
  components: {
    SectionList: () => import("./SectionList"),
    AlternativeNameButton: () =>
      import("@/components/reusable/AlternativeNameButton"),
  },
  data() {
    return {
      periods: [],
      sections: [],
      // level: 1,
      // period_id: this.$store.getters.getInstitution.actual_period.id,
      // period_id: 1,
      // career_id: 1,
      // modality_id: 1,
      // campus_id: 1,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      careers: names.CAREERS,
      cycles: names.CYCLES,
      faculties: names.FACULTIES,
    }),
    allows_crud() {
      if (
        this.user &&
        (this.user.is_superuser || this.user.groups.includes(1))
      ) {
        const has_permission = this.$hasPermissions("teaching.add_section");
        if (has_permission) {
          return has_permission;
        } else return false;
      } else {
        this.$store.commit(names.MUTATE_VISIBILITY_CRUD, false);
        return false;
      }
    },
    careerList() {
      return this.careers;
    },
  },
  methods: {
    // fetchPeriods() {
    //   this.$restful
    //     .Get(
    //       `/mesh/period/?institution=${this.$store.getters.getInstitutionId}`
    //     )
    //     .then((response) => {
    //       this.periods = response;
    //     });
    // },
    // slotUpdateSectionList(section_list) {
    //   this.sections = section_list;
    // },
    // slotChangeEgressProfile(egress_profile_id) {
    //   this.selected_egress_profile_id = egress_profile_id;
    // },
  },
  created() {
    this.$store.dispatch(names.FETCH_PROFILE_TYPES);
    this.$store.dispatch(names.FETCH_PERIODS);
    this.$store.dispatch(names.FETCH_SHIFTS);
    this.$store.dispatch(names.FETCH_CAREERS);
    this.$store.dispatch(names.FETCH_CAMPUSES);
    this.$store
      .dispatch(names.FETCH_ALT_USERS, { groups_ids: [6, 7] })
      .then((response) => {
        const map_student = [
          ...new Set(
            response
              .filter(
                (x) =>
                  x.is_student == true &&
                  x.student_card != null &&
                  x.student_card.egress_profile != null
              )
              .map((x) => x.student_card.egress_profile)
          ),
        ];
        map_student.forEach((egress_profile_id) => {
          this.$store.dispatch(names.FETCH_EGRESS_PROFILE, egress_profile_id);
        });
      });
    this.$store.dispatch(names.FETCH_REGIMES);
    // this.allows_crud = this.$store.getters.hasPermission(
    //   "teaching.add_section"
    // );
    // if (this.$route.query.career_id) {
    //   this.career_id = Number(this.$route.query.career_id);
    // }
    // if (this.$route.query.period_id) {
    //   this.period_id = Number(this.$route.query.period_id);
    // }
    // if (this.$route.query.modality_id) {
    //   this.modality_id = Number(this.$route.query.modality_id);
    // }
    // if (this.$route.query.level) {
    //   this.level = Number(this.$route.query.level);
    // }
  },
};
</script>

<style scoped>
.section-container {
  padding: 1rem !important;
  width: 100%;
  background: #eeeeee;
  border-radius: 10px;
}
</style>